<template>
	<div class="animated fadeIn">
		<!-- Search Start -->
		<template v-if="isAdminOrCorporate && !isDashboard">
			<b-alert
				show
				variant="secondary">
				<div class="row">
					<div class="col">
						<search
							ref="searchComp"
							v-model="distributor"
							:label-text="translate('autocomplete_label')"
							:endpoint="endpoint"
							:empty-result-message="translate('data_not_found')"
							:placeholder="'autocomplete_placeholder'"
							:default-params="{role:'distributor,customer,affiliate'}"
							:init-value="this.$user.details().id != distributorId ? String(distributor): ''"
							return-key="id"
							class="mb-3"
							q="label" />
						<div class="row">
							<div class="col">
								<b-button
									:disabled="loading"
									variant="primary"
									class="btn mx-1"
									@click="changeDistributor()">
									{{ translate('search') }}
								</b-button>
								<b-button
									:disabled="loading"
									variant="secondary"
									class="btn mx-1"
									@click="clearDistributor()">
									{{ translate('clear') }}
								</b-button>
							</div>
						</div>
					</div>
				</div>
			</b-alert>
			<hr>
		</template>
		<!-- Search End -->
		<!-- Filters Start -->
		<div class="row">
			<div class="col">
				<b-button
					:variant="!showFilters ? 'primary' : 'secondary'"
					class="btn mx-1 bg-primary-alt"
					@click="showFilters = !showFilters">
					<i
						class="fa fa-filter"
						aria-hidden="true" />
				</b-button>
			</div>
		</div>
		<hr>
		<data-filter
			:display="showFilters"
			get-by="name"
			@submit="getDataFiltered"
			@clear="clearFilters">
			<template slot="form">
				<div class="row">
					<div
						class="col-md-3 col-sm-6 col-xs-12">
						<div
							class="form-group">
							<label
								for="uid"
								class="label">{{ translate('user_id') }}</label>
							<input
								id="uid"
								v-model="filters.uid"
								:placeholder="translate('type_uid')"
								type="text"
								name="uid"
								class="form-control">
						</div>
					</div>
					<div class="col-md-3 col-sm-6 col-xs-12">
						<div class="form-group">
							<label
								for="status"
								class="label">{{ translate('status') }}</label>
							<select
								id="status"
								v-model="filters.status"
								name="status"
								class="form-control">
								<option :value="undefined">
									{{ translate('all') }}
								</option>
								<option
									v-for="status in statuses"
									:key="status"
									:value="status">
									{{ translate(status) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-3 col-sm-6 col-xs-12">
						<div
							class="form-group">
							<label
								for="type"
								class="label">{{ translate('type') }}</label>
							<select
								id="type"
								v-model="filters.type"
								name="type"
								class="form-control">
								<option
									:value=" '' || undefined ">
									{{ translate('all') }}
								</option>
								<option
									v-for="role in rolesWithAutoship"
									:key="role"
									:value="role">
									{{ translate(role) }}
								</option>
							</select>
						</div>
					</div>
					<div class="col-md-3 col-sm-6 col-xs-12">
						<div
							v-if="countryData !== null"
							class="form-group">
							<label
								for="country_code"
								class="label">{{ translate('country') }}</label>
							<select
								id="country_code"
								v-model="filters.country_code"
								:disabled="!countryData.length || staffCountry"
								name="country_code"
								class="form-control">
								<template v-if="!staffCountry">
									<option :value="'' || undefined">
										{{ translate('all') }}
									</option>
									<template v-if="countryData.length">
										<option
											v-for="c in countryData"
											:key="c.attributes.code"
											:value="c.attributes.code">
											{{ translate(c.attributes.code.toLowerCase()) }}
										</option>
									</template>
								</template>
								<template v-if="staffCountry">
									<option :value="countryCode">
										{{ translate(countryCode.toLowerCase()) }}
									</option>
								</template>
							</select>
						</div>
					</div>
				</div>
			</template>
		</data-filter>
		<!-- Filters End -->
		<!-- Tabs Start -->
		<b-tabs
			v-model="tabIndex">
			<b-tab
				v-for="(tabInfo, index) in tabs"
				:key="index">
				<template slot="title">
					<a
						:class="$route.query.enabled === tabInfo.enabled ? 'nav-link ' + $router.options.linkActiveClass : 'text-gray-dark'"
						class="list-group-item btn text-left"
						@click="setTab(tabInfo.enabled)">
						{{ translate(tabInfo.translate_key) }}
						({{ metaData[tabInfo.count] }})
					</a>
				</template>
			</b-tab>
		</b-tabs>
		<!-- Tabs End -->
		<b-row>
			<b-col>
				<div class="table-responsive">
					<table class="table table-hover table-striped text-nowrap">
						<thead>
							<tr>
								<th class="border-top-0">
									#
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('id')">
									{{ translate('user_id') }}
									<sort field="id" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('username')">
									{{ translate('username') }}
									<sort field="username" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('status')">
									{{ translate('status') }}
									<sort field="status" />
								</th>
								<th
									v-if="tabs[tabIndex].enabled === 'all'"
									class="border-top-0 pointer"
									@click="sortByField('is_enabled')">
									{{ translate('autoship') }}
									<sort field="is_enabled" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('type')">
									{{ translate('type') }}
									<sort field="type" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('country')">
									{{ translate('country') }}
									<sort field="country" />
								</th>
								<th
									class="border-top-0 pointer"
									@click="sortByField('total_products')">
									{{ translate('products_qty') }}
									<sort field="total_products" />
								</th>
								<th
									v-if="tabs[tabIndex].enabled === '1'"
									class="border-top-0 pointer"
									@click="sortByField('autoship_date')">
									{{ translate('next_autoship_date') }}
									<span
										v-b-tooltip.hover
										:title="translate('next_purchase_date_info')">
										<i class="fas fa-lg fa-info-circle" />
									</span>
									<sort field="autoship_date" />
								</th>
							</tr>
						</thead>
						<tbody v-if="!loading && hasData">
							<tr
								v-for="(item, index) in data"
								:key="index">
								<td class="text-center">
									{{ ((pagination.per_page * pagination.current_page) - pagination.per_page) + (index + 1) }}
								</td>
								<td
									class="text-center">
									<span
										v-if="isAdminOrCorporate && !customerRoles.includes(item.attributes.type)"
										class="badge badge-primary pointer"
										@click="toDashboard({ distributorId: item.id.toString() })">
										{{ item.id }}
									</span>
									<span
										v-else
										class="badge badge-primary">
										{{ item.id }}
									</span>
								</td>
								<td class="text-center">
									{{ item.attributes.username }}
								</td>
								<td class="text-center">
									{{ translate(item.attributes.status) }}
								</td>
								<td
									v-if="tabs[tabIndex].enabled === 'all'"
									class="text-center">
									{{ translate(`${!!item.attributes.is_enabled}`) }}
								</td>

								<td class="text-center">
									{{ translate(item.attributes.type) }}
								</td>
								<td class="text-center">
									<img
										v-b-tooltip.hover
										class="ml-3"
										:title="translate(item.attributes.country_code.toLowerCase())"
										:src="require(`@/assets/images/common/countries/select/${item.attributes.country_code.toUpperCase()}.png`)"
										:alt="translate(item.attributes.country_code.toLowerCase())">
								</td>
								<td class="text-center">
									{{ item.attributes.total_products }}
								</td>
								<td
									v-if="tabs[tabIndex].enabled === '1'"
									class="text-center">
									{{ $moment(item.attributes.autoship_date.date).format(dateFormat) }}
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<is-loading
			:loading-label="translate('loading')"
			:no-data-label="translate('data_not_found')"
			:loading="loading"
			:has-data="hasData" />
		<b-pagination
			v-if="pagination.total_pages > 1"
			v-model="pagination.current_page"
			:total-rows="pagination.total"
			:per-page="pagination.per_page"
			align="center"
			@change="getDataFiltered" />
	</div>
</template>

<script>
import { PAGINATION } from '@/settings/RequestReply';
import { SEARCH_USERS } from '@/config/endpoint';
import { STATUSES as statuses } from '@/settings/Statuses';
import {
	Grids, Countries, Users, AccountInformation,
} from '@/translations';
import { YMD_FORMAT } from '@/settings/Dates';
import { distributor, customerRoles, admin } from '@/settings/Roles';
import FiltersParams from '@/mixins/FiltersParams';
import DashboardRedirect from '@/mixins/DashboardRedirect';
import Reports from '@/util/Report';
import Country from '@/util/Country';
import Sponsored from '@/views/Network/mixins/Sponsored';
import DataFilter from '@/components/DataFilter';

const { endpoint } = SEARCH_USERS;
export default {
	name: 'AutoshipSummary',
	messages: [Grids, Countries, Users, AccountInformation],
	components: {
		DataFilter,
	},
	mixins: [FiltersParams, DashboardRedirect, Sponsored],
	data() {
		return {
			autoshipSummary: new Reports(),
			country: new Country(),
			statuses,
			admin,
			endpoint,
			customerRoles,
			dateFormat: YMD_FORMAT,
			rolesWithAutoship: { ...customerRoles, distributor },
			tabIndex: 0,
			tabs: [
				{
					count: 'num_total',
					enabled: 'all',
					translate_key: 'all',
				},
				{
					count: 'num_enabled',
					enabled: '1',
					translate_key: 'enabled',
				},
				{
					count: 'num_disabled',
					enabled: '0',
					translate_key: 'disabled',
				},
			],
		};
	},
	computed: {
		loading() {
			try {
				return !!this.autoshipSummary.data.loading;
			} catch (error) {
				return false;
			}
		},
		errors() {
			return this.autoshipSummary.data.errors;
		},
		pagination() {
			return this.autoshipSummary.data.pagination;
		},
		metaData() {
			try {
				const { totals } = this.autoshipSummary.data.response.data.meta;
				return totals[0];
			} catch (error) {
				return {};
			}
		},
		username() {
			try {
				return this.autoshipSummary.data.response.data.meta.username;
			} catch (error) {
				return this.$user.details().type;
			}
		},
		data() {
			try {
				const { data } = this.autoshipSummary.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		hasData() {
			try {
				const response = this.data.length;
				return !!response;
			} catch (error) {
				return false;
			}
		},
		isAdminOrCorporate() {
			return this.admin.includes(this.$user.details().type);
		},
		isDashboard() {
			return this.$route.name === 'DistributorsAutoshipSummary';
		},
		countryData() {
			try {
				const { data } = this.country.data.response.data;
				return data;
			} catch (error) {
				return [];
			}
		},
		distributorId() {
			let { distributorId } = this.$route.params;
			if (typeof distributorId === 'undefined') {
				distributorId = this.$user.details().id;
			}
			return distributorId;
		},
		staffCountry() {
			if (this.admin.includes(this.$user.details().type)) {
				return this.$user.details().country !== undefined;
			}
			return false;
		},
		countryCode() {
			return this.$user.details().country.iso_code_2;
		},
	},
	mounted() {
		const { query } = this.$route;
		this.getSummary();
		this.country.getCountries();
		if (query.enabled) {
			this.tabIndex = this.tabs.findIndex((x) => x.enabled === query.enabled);
		}
		if (this.staffCountry) {
			this.filters.country_code = this.countryCode;
		}
	},
	methods: {
		getSummary(params) {
			this.autoshipSummary.getAutoshipSummary(this.distributorId, params);
		},
		getDataFiltered(page) {
			const { query } = this.$route;
			const options = { ...query, ...this.filters };
			if (typeof page === 'number') {
				options[PAGINATION] = page;
			}

			Object.keys(options).forEach((item) => {
				if (!options[item]) {
					delete options[item];
				}
			});

			this.$router.push({ name: this.$route.name, query: options, params: { distributorId: this.distributorId } });
			this.getSummary(options);
		},
		setTab(enabled) {
			this.filters.enabled = enabled;
			this.getDataFiltered();
		},
	},
};
</script>
